package kotlinReactComponents.kVision

import io.kvision.chart.*
import io.kvision.core.BorderStyle
import io.kvision.core.Color
import io.kvision.react.kv
import io.kvision.types.LocalDateTime
import io.kvision.utils.obj
import react.*
import react.dom.br
import styled.css
import styled.styledDiv


@ExperimentalJsExport
@JsExport external interface SprintChartProps : RProps {
    var datasetLabels: Array<String>
    var labelsYAxisPoints: Array<String>
    var fullDataLists: Array<Array<Any>>
    var currentDataset: Array<Int>
    var presentedDataLists: Array<Array<Any>>
    var dataPointsBackgroundColors: Array<Array<Any>>
    var lineTension: Double



}

@ExperimentalJsExport
@JsExport data class SprintChartState(
    var datasetLabels: Array<String>,
    var labelsYAxisPoints: Array<String>,
    var fullDataLists: Array<Array<Any>>,
    var currentDataset: Array<Int>,
    var presentedDataLists: Array<Array<Any>>,
    var dataPointsBackgroundColors: Array<Array<Any>>,
    var lineTension: Double


) : RState {
    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other == null || this::class.js != other::class.js) return false

        other as SprintChartState

        if (!datasetLabels.contentEquals(other.datasetLabels)) return false
        if (!labelsYAxisPoints.contentEquals(other.labelsYAxisPoints)) return false
        if (!fullDataLists.contentDeepEquals(other.fullDataLists)) return false
        if (!presentedDataLists.contentDeepEquals(other.presentedDataLists)) return false
        if (!dataPointsBackgroundColors.contentDeepEquals(other.dataPointsBackgroundColors)) return false
        if (lineTension != other.lineTension) return false

        return true
    }

    override fun hashCode(): Int {
        var result = datasetLabels.contentHashCode()
        result = 31 * result + labelsYAxisPoints.contentHashCode()
        result = 31 * result + fullDataLists.contentDeepHashCode()
        result = 31 * result + presentedDataLists.contentDeepHashCode()
        result = 31 * result + dataPointsBackgroundColors.contentDeepHashCode()
        result = 31 * result + lineTension.hashCode()
        return result
    }

}

@ExperimentalJsExport
class  SprintChart(props: SprintChartProps) : RComponent<SprintChartProps, SprintChartState>(props){
    init {
        state = SprintChartState(
            datasetLabels = props.datasetLabels,
            labelsYAxisPoints = props.labelsYAxisPoints,
            fullDataLists = props.fullDataLists,
            currentDataset = props.currentDataset,
            presentedDataLists = props.presentedDataLists,
            dataPointsBackgroundColors = props.dataPointsBackgroundColors,
            lineTension = props.lineTension
        )
    }

    override fun RBuilder.render() {
        var chart1: Chart? = null
        styledDiv {
            css {

            }
            styledDiv {
                css {
                }
//                +"numberOfDatapoints: ${state.currentDataset}"
//                br{}
//                +"last updated: ${LocalDateTime.now()}"
//                val labels1 = listOf("Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag",
//                    "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag")

//                val fullDataRealSprint = listOf(63, 61, 54, 48, 40, 40, 40, 50, 42, 38, 33, 30, 25, 20)
                val fullDataPlannedSprint = listOf(63, 56, 49, 42, 35, 35, 35, 28, 21, 14, 7, 0)

//                val currentDataRealSprint : MutableList<Int> = mutableListOf()
                val currentDataRealSprint : MutableList<Int> = mutableListOf()
//                val currentDataPlannedSprint : MutableList<Int> = mutableListOf()

                currentDataRealSprint.addAll(props.currentDataset)

//                var numberOfDataPoints = currentDataRealSprint.size
                kv {
                chart1 = chart(
                    Configuration(
                        type = ChartType.LINE,
                        dataSets  = listOf(
                            DataSets(data = currentDataRealSprint,
                                pointBackgroundColor = mutableListOf(
                                    Color.hex(0x00ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0xffff00),
                                    Color.hex(0xffff00),
                                    Color.hex(0xff9000),
                                    Color.hex(0xff4000),
                                    Color.hex(0xff2000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff2000),
                                ),
                                backgroundColor = listOf(
                                    Color.hex(0x00ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0xffff00),
                                    Color.hex(0xffff00),
                                    Color.hex(0xff9000),
                                    Color.hex(0xff4000),
                                    Color.hex(0xff2000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff0000),
//                    Color.hex(0xff2000),
                                )
                                ,
                                lineTension = 0.3,
                                pointBorderColor = listOf (
                                    Color.hex(0x00ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0x70ff00),
                                    Color.hex(0xffff00),
                                    Color.hex(0xffff00),
                                    Color.hex(0xff9000),
                                    Color.hex(0xff4000),
                                    Color.hex(0xff2000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff0000),
                                    Color.hex(0xff2000),
                                ),
                                borderColor = listOf (
                                    Color.hex(0x000000),
//                    Color.hex(0x70ff00),
//                    Color.hex(0x70ff00),
//                    Color.hex(0x70ff00),
//                    Color.hex(0x70ff00),
//                    Color.hex(0xffff00),
//                    Color.hex(0xffff00),
//                    Color.hex(0xff9000),
//                    Color.hex(0xff4000),
//                    Color.hex(0xff2000),
//                    Color.hex(0xff0000),
//                    Color.hex(0xff0000),
//                    Color.hex(0xff0000),
//                    Color.hex(0x000000)
                                ),
                                label = "Real Sprint",
                                showLine = true,
                                fill = false,
                                borderWidth = listOf(1),
                                borderDash = listOf(0),

                                ),
                            DataSets(data = fullDataPlannedSprint,
                                backgroundColor = listOf(
                                    Color.hex(0x00ff00)
                                ), lineTension = 0.0,
                                minBarLegth = 6,
                                label = "Planned Sprint",
                                showLine = true,
                                fill = false
                            )
                        ),
//                        labels = listOf(),
                            labels = listOf("Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag",
                            "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"),
                        options = ChartOptions(responsive = false,
                            showLines = true,
                            elements = ElementsOptions (
                                line = LineOptions(
                                    borderWidth = 1,
                                    borderColor = Color.hex(0x00ff00),
                                    borderDash = listOf(BorderStyle.RIDGE) )),
                            layout = LayoutOptions (
                                padding = LayoutPaddingObject(left = 50, right = 20, top = 20, bottom = 20)),
                            scales = ChartScales(yAxes = listOf(
                                obj {
                                    ticks = obj {
                                        suggestedMin = 0
                                        suggestedMax = 70
                                    }
                                    label = "Story Points"
                                })
                            ),
                            animation = AnimationOptions (
                                duration = 1000,
                                easing = Eeasing.EASEINOUTEXPO,
//                                onProgress = {
//                                    it.numSteps = 14
//                                    if (numberOfDataPoints < fullDataRealSprint.size) {
//
//                                        chart1?.jsChart?.data?.datasets?.get(0)?.data.push(obj {
//                                            fullDataRealSprint[numberOfDataPoints]
//                                        })
//                                        chart1?.jsChart?.data?.datasets?.get(1)?.data.push(obj {
//                                            y=fullDataPlannedSprint[numberOfDataPoints]
//                                        })
//                                        chart1?.jsChart?.data?.labels?.get(0)?.data.push(obj {
//                                            labels1[numberOfDataPoints]
//                                        })
//
//                                    } else {
////                                        numberOfDataPoints = 0
////                                        currentDataRealSprint.clear()
////                                        currentDataPlannedSprint.clear()
//                                    }
//                                    it.render
//                                },
//                                onComplete = {
//                        currentDataRealSprint.clear()
//                        currentDataPlannedSprint.clear()
//                        currentDataRealSprint.addAll(fullDataRealSprint)
//                        currentDataPlannedSprint.addAll(fullDataPlannedSprint)
//                                    it.render
//                                }

                            )
                        )
                    ),
                    chartWidth = 800,
                    chartHeight = 600
                )
                }
//                GlobalScope.launch {
//                    for (i in 0..fullDataRealSprint.lastIndex) {
//                        setChartData(chart1!!, fullDataRealSprint, i)
//                        delay(1000)
//                    }
//                }
            }

            styledDiv {
                css {

                }
            }
        }
    }
}

fun setChartData(chart: Chart, dataList: List<Any>, numberOfDataPoints: Number?) {
    if (numberOfDataPoints != null) {
        for (i in 0..numberOfDataPoints.toInt()) {
            chart.jsChart?.data?.datasets?.get(0)?.data[i] = dataList[i]
        }
    } else {
        for (i in 0..dataList.lastIndex) {
            chart.jsChart?.data?.datasets?.get(0)?.data[i] = dataList[i]
        }

    }
    chart.update()
}


//fun addDataPoint(chart : Chart) {
//
//}

@ExperimentalJsExport
fun RBuilder.sprintChart (handler: SprintChartProps.() -> Unit): ReactElement {
    return child(SprintChart::class) {
        this.attrs(handler)
    }
}