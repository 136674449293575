package content

import react.*
import react.dom.br
import react.dom.li
import react.dom.p
import styled.*


@ExperimentalJsExport
@JsExport external interface AboutThisPageContentProps : RProps {
    var imprintText: String
}

@ExperimentalJsExport
@JsExport data class AboutThisPageContentState(
    val imprintText: String = "Über diese Seite"
) : RState

@ExperimentalJsExport
class AboutThisPageContent @ExperimentalJsExport constructor(props: AboutThisPageContentProps) : RComponent<AboutThisPageContentProps, AboutThisPageContentState>(props){
    init {
        state = AboutThisPageContentState(

        )
    }

    override fun RBuilder.render() {
        styledH1 {
            css {
            }
            +"Über diese Seite"
        }
        styledDiv {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            p {
                +"Diese Internetseite ist mit Kotlin™/React mit dem JVM IR compiler erstellt. "
                +"Sie dient als Testfeld für den Einsatz neuer innovativer Technologien. "
                +"So ist z.B der IR-Compiler noch in der Beta-Phase."
            }
            p {
                +"Eine Einführung wie eine Internetseite mit Hilfe von Kotlin erstellt werden kann, finden Sie auf: "
                br{}
                styledA {
                    attrs.href = "https://play.kotlinlang.org/hands-on/overview"
                    attrs.target = "_blank"
                    attrs.text("https://play.kotlinlang.org/hands-on/overview")
                }
            }
            p {
                +"Einige der verwendeten Technologien sind noch in der Alpha-Phase und es ist zu erwarten, daß sie in Zukunft "
                +"besser funktionieren werden."
            }
            p{
                +"Im einzelnen wurden folgende Technologien und Libraries verwendet: "
                val kotlinPostfix = "144-kotlin-1.4.30"
                val reactVersion = "17.0.1-pre.$kotlinPostfix"
                val styledVersion = "5.2.0-pre.$kotlinPostfix"
                val kVisionVersion = "4.1.0"
                styledUl {
                    li {
                        +"Kotlin: 1.4.32"
                    }
                    li {
                        +"Kotlin JS Plugin: 1.4.32"
                    }
                    li {
                        +"org.w3c:dom:2.3.0-jaxb-1.0.6"
                    }
                    li {
                        +"org.w3c.css:sac:1.3"
                    }
                    li {
                        +"org.w3c.css:css-validator:20100131"
                    }
                    li {
                        +"org.jetbrains:kotlin-css:1.0.0-pre.$kotlinPostfix"
                    }
                    li {
                        +"org.jetbrains:kotlin-styled:$styledVersion"
                    }
                    li {
                        +"styled-components: ~5.1.1"
                    }
                    li {
                        +"inline-style-prefixer: ~6.0.0"
                    }
                    li {
                        +"org.jetbrains:kotlin-react:$reactVersion"
                    }
                    li {
                        +"org.jetbrains:kotlin-react-dom:$reactVersion"
                    }
                    li {
                        +"org.jetbrains:kotlin-react-router-dom:$styledVersion"
                    }
//                    li {
//                        +"org.jetbrains:kotlin-redux:4.0.5-pre.$kotlinPostfix"
//                    }
//                    li {
//                        +"org.jetbrains:kotlin-react-redux:7.2.1-pre.$kotlinPostfix"
//                    }
                    li {
                        +"io.kvision:kvision:$kVisionVersion"
                    }
                    li {
                        +"io.kvision:kvision-react:$kVisionVersion"
                    }
                    li {
                        +"io.kvision:kvision-chart:$kVisionVersion"
                    }
                }
            }
            p{
                +"Sollten Sie Interesse an den gemachten Erfahrungen haben, so wenden Sie sich bitte an uns."
            }
            p{
                +"Wir helfen Ihnen gerne."
            }
        }
    }
}

@ExperimentalJsExport
fun RBuilder.aboutThisPageContent (handler: AboutThisPageContentProps.() -> Unit): ReactElement {
    return child(AboutThisPageContent::class) {
        this.attrs(handler)
    }
}