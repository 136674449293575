import kotlinx.browser.document
import kotlinx.browser.window
import react.dom.render
import styled.css
import styled.styledDiv

@ExperimentalJsExport
fun main() {
        val companyName = "Nusquam"
        val legalForm = "GmbH"
        val fullCompanyName = StringBuilder("$companyName $legalForm")

        window.onload = {

            // rendering the head does not work yet.
            // Turning this on removes a lot of attributes and css from the body
//            render(document.head) {
//                child(DocumentHead::class) {
//                    attrs {
//                        titleName = fullCompanyName.toString()
//                    }
//                }
//            }
            document.body!!.style.backgroundColor = BaseLayoutStyles.baseColor.toString()
            render(document.getElementById("root")) {
                child(BaseLayout::class) {
                    attrs {
                        name = "Kotlin/JS"
                        this.companyName = companyName
                        this.legalForm = legalForm
                    }
                }
            }
        }
    }
