import styled.StyleSheet
import kotlinx.css.*
import kotlinx.css.properties.boxShadow

object DropdownMenuStyles : StyleSheet("DropdownMenuStyles", isStatic = true) {

    val noBorder by css {
        borderStyle = BorderStyle.none
        borderBottomWidth = 0.px
    }

    val emptyRuleSet by css {
    }

    val dropdown by css {
        position = Position.relative
        display = Display.inlineBlock
        minWidth = 75.px
        padding(vertical = 2.px, horizontal = 5.px)
    }

    val boldText by css {
        fontWeight = FontWeight.bold
    }

    val normalText by css {
        fontWeight = FontWeight.normal
    }

    val contentElementBorder by css {
        borderStyle = BorderStyle.solid
        borderBottomWidth = 1.px
        borderLeftWidth = 0.px
        borderRightWidth = 0.px
        borderTopStyle = BorderStyle.none
    }

    val dropdownContent by css {
        position = Position.absolute
        backgroundColor = rgba(0xf9, 0xf9, 0xf9, 1.0)
        minWidth = 75.px
        boxShadow(color = rgba(0,0,0,0.2), offsetX = 0.px, offsetY = 8.px, blurRadius = 16.px, spreadRadius = 0.px)
        padding(vertical = 12.px, horizontal = 10.px)
        zIndex = 1
    }



    val displayBlock by css {
        display = Display.block
    }
    val displayNone by css {
        display = Display.none
    }



}