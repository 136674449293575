@file:Suppress("unused")

import io.kvision.utils.auto
import kotlinx.css.*
import kotlinx.css.Color.Companion.black
import kotlinx.css.Color.Companion.cyan
import kotlinx.css.Color.Companion.white
import kotlinx.css.VerticalAlign.Companion.bottom
import kotlinx.css.VerticalAlign.Companion.middle
import styled.StyleSheet


object BaseLayoutStyles : StyleSheet("BaseLayoutStyles", isStatic = true) {



    val baseColor = rgba (30,140,255,1.0)


    val col_01 by css {
        width = 5.pct
    }

    val col_02 by css {
        width = 10.pct
    }

    val col_03 by css {
        width = 15.pct
    }

    val col_04 by css {
        width = 20.pct
    }

    val col_05 by css {
        width = 25.pct
    }

    val col_06 by css {
        width = 30.pct
    }

    val col_07 by css {
        width = 35.pct
    }

    val col_08 by css {
        width = 40.pct
    }

    val col_09 by css {
        width = 45.pct
    }

    val col_10 by css {
        width = 50.pct
    }

    val col_11 by css {
        width = 55.pct
    }

    val col_12 by css {
        width = 60.pct
    }

    val col_13 by css {
        width = 65.pct
    }

    val col_14 by css {
        width = 70.pct
    }

    val col_15 by css {
        width = 75.pct
    }

    val col_16 by css {
        width = 80.pct
    }

    val col_17 by css {
        width = 85.pct
    }

    val col_18 by css {
        width = 90.pct
    }

    val col_19 by css {
        width = 95.pct
    }

    val col_20 by css {
        width = 100.pct
    }

    private val headerHeightValue = 10.0.em
    private val navigationHeightValue = 6.5.em
    private val footerHeightValue = 1.5.em
    private val articleHeightValue = 100.vh-(headerHeightValue + navigationHeightValue + footerHeightValue)
//    val articleHeightValue = 100.pct


    val companyNameFontSize = 4.0.em
    val navigationBarFontSize = 2.25.em
    val companySloganFontSize = 1.5.em
    val standartFontSize = 1.em

    val fullHeight by css {
        height = 100.vh
//        height = 100.pct
        maxHeight = 100.vh
//        minHeight = 95.vh
//        maxHeight = 99.vh
    }

    val fullHeightPct by css {
        height = 100.pct
        maxHeight = 100.pct
//        minHeight = 95.vh
//        maxHeight = 99.vh
    }

    val autoHeight by css {
        height = LinearDimension("auto")
    }

    val footerHeight by css {
        height = footerHeightValue
    }

    val headerHeight by css {
        height = headerHeightValue
        maxHeight = headerHeightValue
    }

    val navigationHeight by css {
//        position = Position.absolute
//        top = 0.em
        height = navigationHeightValue
        maxHeight = navigationHeightValue
    }

    val articleHeight by css {
//        minHeight = LinearDimension("auto")
//        minHeight = articleHeightValue
        minHeight = articleHeightValue
        height = articleHeightValue
        maxHeight = articleHeightValue
    }

    val height5pc by css {
        height = 5.pc
    }

    val height10vh by css {
        height = 10.vh
    }

    val height20vh by css {
        height = 20.vh
    }

    val height80vw by css {
        height = 20.vh
    }

    val fullWidth by css {
        width = 100.pct
    }
    val quarterWidth by css {
        width = 25.vw
    }
    val width75vw by css {
        width = 75.vw
    }

    val width55vw by css {
        width = 55.vw
    }

    val width50vw by css {
        width = 50.vw
    }

    val width60vw by css {
        width = 60.vw
    }



    val width85vw by css {
        width = 85.vw
    }

    val width25vw by css {
        width = 25.vw
    }

    val width15vw by css {
        width = 15.vw
    }

    val width10vw by css {
        width = 10.vw
    }

    val width6em by css {
        minWidth = 6.em
        width = 6.em
        maxWidth = 6.em
    }

    val width8em by css {
        minWidth = 8.em
        width = 8.em
        maxWidth = 8.em
    }

    val width6To9em by css {
        minWidth = 6.em
        width = 6.5.em
        maxWidth = 13.em
    }

    val width10em by css {
        minWidth = 9.em
        width = 9.em
        maxWidth = 9.em
    }

    val widthVwMinusSloganWidth by css {
        width = 100.vw-12.em
    }

    val widthVwMinusNavItemsWidth by css {
        width = 100.vw-25.em
    }


    val baseBackgroundColor by css {
         backgroundColor = baseColor
    }

    val gradientBackground by css {
        put("backgroundImage", "linear-gradient(170deg, rgba(30,140,255,1) 0%, rgba(120,210,255,1) 85%, rgba(200,200,255,100) 100%)")
    }

    val overflowScroll by css {
        overflow = Overflow.scroll
    }

    val overflowAuto by css {
        overflow = Overflow.auto
    }
    val overflowHidden by css {
        overflow = Overflow.hidden
    }


    val marginHorizontal2em by css {
        marginLeft = 2.em
        marginRight = 2.em
    }

    val marginHorizontalAuto by css {
        display = Display.block
        marginLeft = LinearDimension.auto
        marginRight = LinearDimension.auto
        width = 830.px
    }
    val paddingToCenterWelcomeVertically by css {
        paddingTop = (articleHeightValue - 4.em) / 2.0
        paddingBottom = (articleHeightValue - 4.em) / 2.0
    }

    val paddingHorizontal1em by css {
        paddingLeft = 1.em
        paddingRight = 1.em
    }

    val paddingMarginZero by css {
        padding(0.px)
        margin(0.px)
    }

    val noMarginNoPaddingNoBorders by css {
        margin = "0px 0px"
        padding = "0px 0px"
        borderWidth = 0.px
        borderStyle = BorderStyle.none
    }

    val solidBorder1px by css {
        border = "solid"
        borderWidth = 1.px
    }

    val dottedBorder1px by css {
        border = BorderStyle.dotted.toString()
        borderWidth = 1.px
    }

    val stdtTableLayout by css {
        position = Position.static
        tableLayout = TableLayout.fixed
        noMarginNoPaddingNoBorders
    }

    val boxSizingBorderBox by css {
        boxSizing = BoxSizing.borderBox
    }

    val alignSelfStart by css {
        alignSelf = Align.flexStart
    }

    val alignSelfStretch by css {
        alignSelf = Align.stretch
    }

    val alignSelfCenter by css {
        alignSelf = Align.center
    }

    val alignSelfBaseline by css {
        alignSelf = Align.baseline
    }

    val alignItemsStretch by css {
        alignItems = Align.stretch
    }

    val alignItemsStart by css {
        alignItems = Align.flexStart
    }
    val alignItemsCenter by css {
        alignItems = Align.center
    }

    val alignItemsEnd by css {
        alignItems = Align.flexEnd
    }

    val alignContainerBaseline by css {
        alignContent = Align.baseline
    }

    val alignContentAtEnd by css {
        alignContent = Align.end
    }

    val alignContentAtStart by css {
        alignContent = Align.start
    }

    val alignContentAtCenter by css {
        alignContent = Align.center
    }

    val displayBlock by css {
        display = Display.block
    }
    val displayNone by css {
        display = Display.none
    }

    val alignTextRight by css {
        textAlign = TextAlign.right
    }

    val alignTextCenter by css {
        textAlign = TextAlign.center
    }

    val alignTextLeft by css {
        textAlign = TextAlign.left
    }

    val alignTextJustify by css {
        textAlign = TextAlign.justify
    }

    val blackLetters by css {
        color = black
    }

    val whiteLetters by css {
        color = white
    }

    val navigationBar by css {
        fontSize = navigationBarFontSize
    }

    val companySlogan by css {
        fontSize = companySloganFontSize
    }

    val top0px by css {
        top = 0.pct
    }

    val bottom0px by css {
        bottom = 0.pct
    }

    val article  by css {
//        position = Position.absolute
//        top = 0.pct
//        paddingTop = (headerHeightValue + navigationHeightValue +5.em)
//        paddingBottom = footerHeightValue
    }

    val footer  by css {
        fontSize = standartFontSize
        position = Position.static
        alignContent = Align.center
        textAlign = TextAlign.center
        bottom = 0.pct
//        marginBottom = 2.px
//        paddingTop = 5.px
    }

    val stdButton by css {
        marginTop = 1.em
        paddingLeft = 2.em
        paddingRight = 2.em
    }

    val companyName by css {
        backgroundColor = baseColor
        textAlign = TextAlign.center
        fontSize = companyNameFontSize
        fontStyle = FontStyle.italic
    }

    val textContainer by css {
        padding(5.px)
        fontSize = 36.pt
        backgroundColor = rgb(8, 97, 22)
        color = rgb(56, 246, 137)
    }

    val textInput by css {
        margin(vertical = 20.pt)
        backgroundColor = cyan
        fontSize = 18.px
    }

    val standardFont by css {
        put ("font-family", "\"Times New Roman\", Times, serif")
//        put ("font-family", "Georgia, serif")
//        put ("font-family", "Garamond, serif")
//        put ("font-family", "Tahoma, Verdana, sans-serif")
    }
} 
