package kotlinReactComponents

import DropdownMenuStyles
import kotlinx.css.RuleSet
import kotlinx.html.js.onMouseDownFunction
import kotlinx.html.js.onMouseOutFunction
import kotlinx.html.js.onMouseOverFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css
import styled.styledDiv
import styled.styledSpan


@ExperimentalJsExport
@JsExport external interface DropdownMenuProps : RProps {
    var title: String
    var entries: Array<String>
    var dropdownVisible: Boolean
    var clickedEntry: String
    var onMouseDownDropdownMenu: (Event) -> Unit
    var onMouseDownDropdownTitle: (Event) -> Unit
    var titleStyleRuleSet: RuleSetClass
    var entryBlockRuleSets: RuleSetClass
    var eachEntryStyleRuleSet: RuleSetClass
}

@ExperimentalJsExport
@JsExport data class RuleSetClass (val ruleSets: Array<RuleSet> = arrayOf (DropdownMenuStyles.emptyRuleSet))

@ExperimentalJsExport
@JsExport data class DropdownMenuState(
    val title: String = "",
    val entries: Array<String> = arrayOf(""),
    val dropdownVisible: Boolean = false,
    val clickedEntry: String = "",
    val mouseIsOver: String = "",
    val titleStyleRuleSet: RuleSetClass = RuleSetClass(),
    var entryBlockRuleSets: RuleSetClass = RuleSetClass(),
    val eachEntryStyleRuleSet: RuleSetClass = RuleSetClass()) : RState

@ExperimentalJsExport
class DropdownMenu @OptIn(ExperimentalJsExport::class) constructor(props: DropdownMenuProps) : RComponent<DropdownMenuProps, DropdownMenuState>(props){
    init {
        state = DropdownMenuState(
            title = props.title,
            entries = props.entries,
            dropdownVisible = props.dropdownVisible,
            clickedEntry = props.clickedEntry,
            mouseIsOver = "",
            titleStyleRuleSet = props.titleStyleRuleSet,
            entryBlockRuleSets = props.entryBlockRuleSets,
            eachEntryStyleRuleSet = props.eachEntryStyleRuleSet)
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                +DropdownMenuStyles.dropdown
                for ( rules in state.titleStyleRuleSet.ruleSets) {
                    +rules
                }
            }
            attrs {
                onMouseOverFunction = { event ->
                    setState(
                        DropdownMenuState(
                            title = state.title,
                            entries = state.entries,
                            dropdownVisible = true,
                            clickedEntry = state.clickedEntry,
                            mouseIsOver = (event.target as HTMLElement).innerText,
                            titleStyleRuleSet = state.titleStyleRuleSet,
                            entryBlockRuleSets = state.entryBlockRuleSets,
                            eachEntryStyleRuleSet = state.eachEntryStyleRuleSet
                        )
                    )
                }
                onMouseOutFunction = {
                    setState(
                        DropdownMenuState(
                            title = state.title,
                            entries = state.entries,
                            dropdownVisible = false,
                            clickedEntry = state.clickedEntry,
                            mouseIsOver = "",
                            titleStyleRuleSet = state.titleStyleRuleSet,
                            entryBlockRuleSets = state.entryBlockRuleSets,
                            eachEntryStyleRuleSet = state.eachEntryStyleRuleSet
                        )
                    )
                }
            }
            styledSpan {

                styledDiv {
                    css {
                        if (state.mouseIsOver == state.title) {
                            +DropdownMenuStyles.boldText
                        } else {
                            +DropdownMenuStyles.normalText
                        }
                    }
                    attrs.text(state.title)
                    attrs {
                        onMouseDownFunction = { event ->
                            props.onMouseDownDropdownTitle (event)
                            setState (
                                DropdownMenuState(
                                    title = state.title,
                                    entries = state.entries,
                                    dropdownVisible = true,
                                    clickedEntry = (event.target as HTMLElement).innerText,
                                    mouseIsOver = state.mouseIsOver,
                                    titleStyleRuleSet = state.titleStyleRuleSet,
                                    entryBlockRuleSets = state.entryBlockRuleSets,
                                    eachEntryStyleRuleSet = state.eachEntryStyleRuleSet,
                                )
                            )
                        }
                    }
                }

                styledDiv {
                    css {
                        +DropdownMenuStyles.dropdownContent
                        if (state.dropdownVisible) {
                            +DropdownMenuStyles.displayBlock
                        } else {
                            +DropdownMenuStyles.displayNone
                        }

                        for (i in 0..state.entryBlockRuleSets.ruleSets.lastIndex) {
                            +state.entryBlockRuleSets.ruleSets[i]
                        }
                    }

                    attrs {
                        onMouseDownFunction = { event ->
                            props.onMouseDownDropdownMenu (event)
                            setState (
                                DropdownMenuState(
                                    title = state.title,
                                    entries = state.entries,
                                    dropdownVisible = false,
                                    clickedEntry = (event.target as HTMLElement).innerText,
                                    mouseIsOver = state.mouseIsOver,
                                    titleStyleRuleSet = state.titleStyleRuleSet,
                                    entryBlockRuleSets = state.entryBlockRuleSets,
                                    eachEntryStyleRuleSet = state.eachEntryStyleRuleSet,
                                )
                            )
                        }
                    }

                    for (index in 0..state.entries.lastIndex) {
                        styledDiv {
                            css {
                                if (state.mouseIsOver == state.entries[index]) {
                                    +DropdownMenuStyles.boldText
                                } else {
                                    +DropdownMenuStyles.normalText
                                }
                                if (index == state.entries.lastIndex) {
                                    +DropdownMenuStyles.noBorder
                                } else {
                                    +DropdownMenuStyles.contentElementBorder
                                }
                                for (i in 0..state.eachEntryStyleRuleSet.ruleSets.lastIndex) {
                                    +state.eachEntryStyleRuleSet.ruleSets[i]
                                }

                            }
                            attrs.text(state.entries[index])
                            attrs {
                                onMouseOverFunction = { event ->
                                    setState(
                                        DropdownMenuState(
                                        title = state.title,
                                        entries = state.entries,
                                        dropdownVisible = false,
                                        clickedEntry = state.clickedEntry,
                                        mouseIsOver = (event.target as HTMLElement).innerText,
                                        titleStyleRuleSet = state.titleStyleRuleSet,
                                        entryBlockRuleSets = state.entryBlockRuleSets,
                                        eachEntryStyleRuleSet = state.eachEntryStyleRuleSet,
                                        )
                                    )
                                }
                                onMouseOutFunction = {
                                    setState(
                                        DropdownMenuState(
                                            title = state.title,
                                            entries = state.entries,
                                            dropdownVisible = false,
                                            clickedEntry = state.clickedEntry,
                                            mouseIsOver = "",
                                            titleStyleRuleSet = state.titleStyleRuleSet,
                                            entryBlockRuleSets = state.entryBlockRuleSets,
                                            eachEntryStyleRuleSet = state.eachEntryStyleRuleSet,
                                        )
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


