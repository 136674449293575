package content

import react.*
import react.dom.br
import styled.css
import styled.styledDiv
import styled.styledH1


@ExperimentalJsExport
@JsExport external interface WelcomeContentProps : RProps {
    var welcomeText: String
}

@ExperimentalJsExport
@JsExport data class WelcomeContentState(
    val welcomeText: String = ""
) : RState

@ExperimentalJsExport
class WelcomeContent @ExperimentalJsExport constructor(props: WelcomeContentProps) : RComponent<WelcomeContentProps, WelcomeContentState>(props){
    init {
        state = WelcomeContentState(

        )
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                +BaseLayoutStyles.fullHeightPct
            }
        }
        styledH1 {
            css {
                +BaseLayoutStyles.fullHeightPct
                +BaseLayoutStyles.fullWidth
                +BaseLayoutStyles.alignSelfCenter
                +BaseLayoutStyles.paddingToCenterWelcomeVertically
//                +BaseLayoutStyles.solidBorder1px
            }
            +"Willkommen"
            br {}
            +"bei der"
            br {}
            +"Nusquam GmbH"
        }

    }
}

@ExperimentalJsExport
fun RBuilder.welcomeContent (handler: WelcomeContentProps.() -> Unit): ReactElement {
    return child(WelcomeContent::class) {
        this.attrs(handler)
    }
}