package content

import BaseLayoutStyles
import react.*
import react.dom.br
import react.dom.li
import react.dom.p
import react.dom.ul
import styled.*


@ExperimentalJsExport
@JsExport external interface ImprintContentProps : RProps {
    var emailAddressToUse: String
}

@ExperimentalJsExport
@JsExport data class ImprintContentState(
    val emailAddressToUse: String = ""
) : RState

@ExperimentalJsExport
class ImprintContent @ExperimentalJsExport constructor(props: ImprintContentProps) : RComponent<ImprintContentProps, ImprintContentState>(props){
    init {
        state = ImprintContentState(
            emailAddressToUse = props.emailAddressToUse
        )
    }

    override fun RBuilder.render() {
        styledH1 {
            css {

            }
            if (state.emailAddressToUse != undefined) {
                attrs.text("Impressum ${state.emailAddressToUse}")
            } else {
                attrs.text("Impressum")
            }
        }

        styledSpan {
            css {

            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Nusquam GmbH"
                br{}
                +"Maiäckerstraße 10"
                br{}
                +"74417 Gschwend"
                br{}
                br{}
                +"Tel.: +49 17664455973"
                br{}
                +"E-Mail: cont@nusquam.gmbh"
                br{}
                br{}
                +"Ust-ID: DE 322090418"
                br{}
                +"HRB Ulm/Donau:  737782"
                br{}
                br{}
                +"Vertretungsberechtigter Geschäftsführer: Bernd Sell"
                br{}
            }

            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Disclaimer"
            }
            styledDiv{
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. "
                    +"Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen."
                }
                p {
                    +"Als Dienste- und Dienstleistungsanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. "
                    +"Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder "
                    +"gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, "
                    +"die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der "
                    +"Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. "
                }
                p {
                    +"Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. "
                    +"Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen."
                }
            }
            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Haftung für Links"
            }
            styledDiv{
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Unser Angebot enthält Links zu Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. "
                    +"Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. "
                }
                p {
                    +"Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. "
                    +"Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. "
                    +"Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. "
                }
                p {
                    +"Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. "
                    +"Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen."
                }

            }

            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Urheberrecht"
            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Die durch die Seitenbetreiber erstellten Inhalte und Werke unterliegen dem deutschen Urheberrecht. "
                    +"Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung, außerhalb der Grenzen des Urheberrechtes, bedürfen "
                    +"der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. "
                }
                p {
                    +"Downloads und Kopien dieser Seite sind nur für den privaten, "
                    +"nicht kommerziellen Gebrauch gestattet."
                }
                p {
                    +"Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, "
                    +"werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. "
                    +"Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. "
                    +"Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen."
                }
            }

            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Datenschutz"
            }
            styledDiv{
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. "
                    +"Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, "
                    +"erfolgt dies, soweit möglich, stets auf freiwilliger Basis. "
                    +"Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. "
                }
                p {
                    +"Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. "
                    +"Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich."
                }
                p {
                    +"Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung "
                    +"von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. "
                    br{}
                    +"Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, "
                    +"etwa durch Spam-Mails, vor."
                }
            }

            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Datenschutzerklärung"
            }
            styledDiv{
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist: "
                br{}
                br{}
                +"Nusquam GmbH"
                br{}
                +"Maiäckerstraße 10"
                br{}
                +"74417 Gschwend"
                br{}
                br{}
                +"Tel.: +49 176 64455973"
                br{}
                +"E-Mail: com@nusquam.gmbh"
                br{}
                br{}
                +"Ust-ID: DE 322090418"
                br{}
                +"HRB Ulm/Donau:  737782"
                br{}
                br{}
                +"Vertretungsberichtigter Geschäftsführer: Bernd Sell"
            }
            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Ihre Rechte als Betroffene"
            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Bei unserem Datenschutzbeauftragten können Sie folgende Rechte ausüben:"
                br{}
                ul{
                    li {+"Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO)"}
                    li {+"Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO)"}
                    li {+"Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO)"}
                    li {+"Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO)"}
                    li {+"Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)"}
                    li {+"Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO)."}
                    li {+"Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen."}
                    li {+"Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige"
                        +"Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde."
                    }
                }
                +"Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:"
                br{}
                styledA {
                    attrs.href = "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                    attrs.text("https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html")
                    attrs.target = "_blank"
                }
            }
            styledH1 {
                css {
                    +BaseLayoutStyles.alignTextCenter
                }
                +"Kontaktformular"
            }
            styledH2 {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Art  und Zweck der im Kontaktformular erhobenen Daten"
            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Die von Ihnen eingegebenen Daten werden zum Zweck der Kommunikation mit Ihnen gespeichert. "
                    +"Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. "
                    +"Diese dienen der Zuordnung und Beantwortung der Anfrage . "
                }
                p {
                    +"Die Angabe weiterer Daten ist freiwillig."
                }
            }
            styledH2 {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Rechtsgrundlage"
            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO bildet die Grundlage der Verarbeitung der im "
                    +"Kontaktformular eingegebenen Daten. "
                }
                p {
                    +"Die von ihnen gemachten Angaben werden zum Zwecke der Bearbeitung ihrer Anfrage und möglicher Anschlussfragen gespeichert. "
                }
                p {
                    +"Sollten Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, so erfolgt die Datenverarbeitung "
                    +"zur Durchführung vorvertraglicher Maßnahmen nach Art 6 Abs. 1 lit. b. DSGVO"
                }
            }
            styledH2 {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Empfänger"
            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Die Empfänger der Daten sind möglicherweise Auftragsverarbeiter"
                }
            }
            styledH2 {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                +"Speicherdauer"
            }
            styledDiv {
                css {
                    +BaseLayoutStyles.alignTextLeft
                }
                p {
                    +"Die Daten ihrer Anfrage werden nach 6 Monaten gelöscht, es sei denn, es kommt zu einem Vertragsverhältnis. "
                    +"Dann unterliegen die Daten der gesetzlichen Aufbewahrungsfristen nach HGB und wir löschen Ihre Daten nach Ablauf dieser Fristen."
                }
            }

        }


    }
}

@ExperimentalJsExport
fun RBuilder.imprintContent (handler: ImprintContentProps.() -> Unit): ReactElement {
    return child(ImprintContent::class) {
        this.attrs(handler)
    }
}