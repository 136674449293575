import content.*
import io.kvision.types.LocalDateTime
import kotlinReactComponents.CompanySlogan
import kotlinReactComponents.DropdownMenu
import kotlinReactComponents.NavigationElement
import kotlinReactComponents.RuleSetClass
import kotlinx.html.id
import org.w3c.dom.HTMLElement
import react.*
import styled.*

@ExperimentalJsExport
@JsExport
external interface BaseLayoutProps : RProps {
    var name: String
    var companyName: String
    var legalForm: String
}


@ExperimentalJsExport
@JsExport
data class BaseLayoutState(
    val timeOfLastStateUpdate: Double = LocalDateTime.now(),
    val name: String,
    val companyName: String,
    val legalForm: String,
    val articleToShow: String = "",
    val updateArticle: Boolean = false,
    val clickedEntryProductsAndServices: String = "",
    val clickedEntryCompany: String = "",
    val clickedAboutThisPage: String = ""
    ): RState

@ExperimentalJsExport
class BaseLayout(props: BaseLayoutProps) : RComponent<BaseLayoutProps, BaseLayoutState>(props) {

    init {
        state = BaseLayoutState(
            name = props.name,
            companyName = props.companyName,
            legalForm = props.legalForm,
            articleToShow = "",
            clickedEntryProductsAndServices = "",
            clickedEntryCompany = "",
            clickedAboutThisPage = ""
        )
    }

    @ExperimentalJsExport
    override fun RBuilder.render() {
        val fullCompanyName = StringBuilder(state.companyName + " " + state.legalForm)

        styledTable {
            attrs.id = "headerTable"
            css {
                +BaseLayoutStyles.noMarginNoPaddingNoBorders
                +BaseLayoutStyles.baseBackgroundColor
                +BaseLayoutStyles.fullWidth
//                +BaseLayoutStyles.fullHeight
                +BaseLayoutStyles.stdtTableLayout
                +BaseLayoutStyles.alignContentAtEnd
                +BaseLayoutStyles.overflowHidden
                +BaseLayoutStyles.standardFont
//                +BaseLayoutStyles.dottedBorder1px
            }

            styledTbody {
                attrs.id = "headerTableBody"
                css {
                    +BaseLayoutStyles.noMarginNoPaddingNoBorders
//                    +BaseLayoutStyles.solidBorder1px
//                    +BaseLayoutStyles.top0px
//                    +BaseLayoutStyles.headerHeight
                }
                styledTr {
                    attrs.id = "headerTableRow"
                    css {
                        +BaseLayoutStyles.noMarginNoPaddingNoBorders
                        +BaseLayoutStyles.top0px
                    }
                    styledTd {
                        attrs.id = "headerTd1"
                        css {
                            +BaseLayoutStyles.noMarginNoPaddingNoBorders
                            +BaseLayoutStyles.widthVwMinusSloganWidth
                            +BaseLayoutStyles.top0px
                        }
                        styledHeader {
                            css {
                                +BaseLayoutStyles.noMarginNoPaddingNoBorders
                                +BaseLayoutStyles.companyName
                                +BaseLayoutStyles.blackLetters
                                +BaseLayoutStyles.alignTextCenter
                            }
                            +fullCompanyName.toString()
                        }
                    }
                    //Company Slogan
                    styledTd {
                        attrs.id = "headerTd2"
                        css {
                            +BaseLayoutStyles.noMarginNoPaddingNoBorders
                            +BaseLayoutStyles.width8em
                            +BaseLayoutStyles.top0px
                        }
                        child(CompanySlogan::class) {
                            attrs.topLineText = state.companyName
                            attrs.bottomLineText = "better Software"
                            attrs.sloganStyleRuleSet = RuleSetClass(arrayOf(
                                BaseLayoutStyles.companySlogan,
                                BaseLayoutStyles.gradientBackground,
                                BaseLayoutStyles.paddingMarginZero,
                                BaseLayoutStyles.alignTextRight,
                                BaseLayoutStyles.alignContentAtEnd
                            ))
                            attrs.topLineStyleRuleSets = RuleSetClass(arrayOf(
                                BaseLayoutStyles.companySlogan,
                                BaseLayoutStyles.blackLetters,
                                BaseLayoutStyles.alignTextCenter
                            ))
                            attrs.bottomLineStyleRuleSet = RuleSetClass(arrayOf(
                                BaseLayoutStyles.companySlogan,
                                BaseLayoutStyles.whiteLetters,
                                BaseLayoutStyles.alignTextCenter
                            ))
                        }
                    }
                }
            }
        }
        styledTable {
            attrs.id = "navigationTable"
            css {
                +BaseLayoutStyles.noMarginNoPaddingNoBorders
                +BaseLayoutStyles.baseBackgroundColor
                +BaseLayoutStyles.fullWidth
//                +BaseLayoutStyles.fullHeight
                +BaseLayoutStyles.stdtTableLayout
                +BaseLayoutStyles.alignContentAtEnd
//                    +BaseLayoutStyles.overflowHidden
                +BaseLayoutStyles.standardFont
//                +BaseLayoutStyles.dottedBorder1px
            }

            styledTbody {
                attrs.id = "navTableBody"
                css {
                    +BaseLayoutStyles.noMarginNoPaddingNoBorders
//                    +BaseLayoutStyles.solidBorder1px
//                    +BaseLayoutStyles.top0px
//                    +BaseLayoutStyles.headerHeight
                }
                //Navigation Bar
                styledTr {
                    attrs.id = "navRow"
                    css {
                        +BaseLayoutStyles.noMarginNoPaddingNoBorders
                        +BaseLayoutStyles.navigationBar
                        +BaseLayoutStyles.alignContentAtEnd
                        +BaseLayoutStyles.alignTextRight
                        +BaseLayoutStyles.top0px
                        +BaseLayoutStyles.solidBorder1px
                    }

                    styledTd {
                        css {
                            +BaseLayoutStyles.widthVwMinusNavItemsWidth
                            +BaseLayoutStyles.alignContentAtEnd
                            +BaseLayoutStyles.alignTextRight
                        }
                    }
                    styledTd {
                        css {
                            +BaseLayoutStyles.alignContentAtEnd
                            +BaseLayoutStyles.alignTextCenter
                            +BaseLayoutStyles.width6To9em
                        }
                        child(DropdownMenu::class) {
                            val entr = ArrayList<String>()
                            entr.add("Optimierung des Entwicklungsprozesses")
                            entr.add("Software Entwicklung")

                            attrs.title = "Produkte und Leistungen"
                            attrs.entries = entr.toTypedArray()
                            attrs.dropdownVisible = state.clickedEntryProductsAndServices.isNotEmpty()

                            attrs.clickedEntry = ""
                            attrs.onMouseDownDropdownTitle = { event ->
                                setState(
                                    BaseLayoutState(
                                        name = state.name,
                                        companyName = state.companyName,
                                        legalForm = state.legalForm,
                                        articleToShow = (event.target as HTMLElement).innerText,
                                        clickedEntryProductsAndServices = (event.target as HTMLElement).innerText,
                                        clickedEntryCompany = "",
                                        clickedAboutThisPage = ""
                                    )
                                )
                            }
                            attrs.onMouseDownDropdownMenu = { event ->
                                setState(
                                    BaseLayoutState(
                                        name = state.name,
                                        companyName = state.companyName,
                                        legalForm = state.legalForm,
                                        articleToShow = (event.target as HTMLElement).innerText,
                                        clickedEntryProductsAndServices = (event.target as HTMLElement).innerText,
                                        clickedEntryCompany = "",
                                        clickedAboutThisPage = ""
                                    )
                                )
                            }
                            attrs.titleStyleRuleSet = RuleSetClass()
                            attrs.entryBlockRuleSets =
                                RuleSetClass(arrayOf(BaseLayoutStyles.gradientBackground))
                            attrs.eachEntryStyleRuleSet =
                                RuleSetClass(arrayOf(BaseLayoutStyles.alignTextCenter))
                        }
                    }
                    styledTd {
                        css {
                            +BaseLayoutStyles.alignContentAtEnd
                            +BaseLayoutStyles.alignTextRight
                            +BaseLayoutStyles.width6em
                        }
                        child(DropdownMenu::class) {
                            val entr = ArrayList<String>()
//                                entr.add("Kontakt")
                            entr.add("Impressum")

                            attrs.title = "Unternehmen"
                            attrs.entries = entr.toTypedArray()
                            attrs.dropdownVisible = state.clickedEntryCompany.isNotEmpty()
                            attrs.clickedEntry = ""
                            attrs.onMouseDownDropdownMenu = { event ->
                                setState(
                                    BaseLayoutState(
                                        name = state.name,
                                        companyName = state.companyName,
                                        legalForm = state.legalForm,
                                        articleToShow = (event.target as HTMLElement).innerText,
                                        clickedEntryProductsAndServices = "",
                                        clickedEntryCompany = (event.target as HTMLElement).innerText,
                                        clickedAboutThisPage = ""
                                    )
                                )
                            }
                            attrs.onMouseDownDropdownTitle = { event ->
                                setState(
                                    BaseLayoutState(
                                        name = state.name,
                                        companyName = state.companyName,
                                        legalForm = state.legalForm,
                                        articleToShow = (event.target as HTMLElement).innerText,
                                        clickedEntryProductsAndServices = "",
                                        clickedEntryCompany = (event.target as HTMLElement).innerText,
                                        clickedAboutThisPage = ""
                                    )
                                )
                            }
                            attrs.titleStyleRuleSet = RuleSetClass()
                            attrs.entryBlockRuleSets =
                                RuleSetClass(arrayOf(BaseLayoutStyles.gradientBackground))
                            attrs.eachEntryStyleRuleSet =
                                RuleSetClass(arrayOf(BaseLayoutStyles.alignTextCenter))
                        }
                    }
                    styledTd {
                        css {
                            +BaseLayoutStyles.width10em
                            +BaseLayoutStyles.noMarginNoPaddingNoBorders
                            +BaseLayoutStyles.alignTextCenter
                            +BaseLayoutStyles.alignContentAtEnd
                        }
                        styledDiv {
                            child(NavigationElement::class) {
                                attrs.text = "über diese Seite"
                                attrs.onMouseDownDropdownMenu = { event ->
                                    setState(
                                        BaseLayoutState(
                                            name = state.name,
                                            companyName = state.companyName,
                                            legalForm = state.legalForm,
                                            articleToShow = (event.target as HTMLElement).innerText,
                                            clickedEntryProductsAndServices = "",
                                            clickedEntryCompany = "",
                                            clickedAboutThisPage = (event.target as HTMLElement).innerText
                                        )
                                    )
                                }
                                attrs.textStyleRuleSet = RuleSetClass()
                            }
                        }
                    }
                }
            }
        }
        styledTable {
            attrs.id = "articleTable"
            css {
                +BaseLayoutStyles.noMarginNoPaddingNoBorders
                +BaseLayoutStyles.baseBackgroundColor
                +BaseLayoutStyles.fullWidth
//                +BaseLayoutStyles.fullHeight
                +BaseLayoutStyles.stdtTableLayout
                +BaseLayoutStyles.alignContentAtEnd
                +BaseLayoutStyles.overflowHidden
                +BaseLayoutStyles.standardFont
//                +BaseLayoutStyles.dottedBorder1px
            }

            styledTbody {
                attrs.id = "articleTableBody"
                css {
                    +BaseLayoutStyles.noMarginNoPaddingNoBorders
//                    +BaseLayoutStyles.solidBorder1px
//                    +BaseLayoutStyles.top0px
//                    +BaseLayoutStyles.headerHeight
                }


                styledTr {
                    attrs.id = "articleRow"
                    css {
                        +BaseLayoutStyles.fullWidth
                        +BaseLayoutStyles.paddingMarginZero
//                    +BaseLayoutStyles.articleHeight
//                    +BaseLayoutStyles.solidBorder1px

                        +BaseLayoutStyles.alignSelfStart
                        +BaseLayoutStyles.alignContentAtStart
                        +BaseLayoutStyles.top0px
                        +BaseLayoutStyles.alignItemsStart
                        +BaseLayoutStyles.alignSelfStart
                    }
                    styledTd {
                        css {
                            +BaseLayoutStyles.noMarginNoPaddingNoBorders

//                        +BaseLayoutStyles.dottedBorder1px
//                        +BaseLayoutStyles.articleHeight
//                        +BaseLayoutStyles.overflowScroll
                            +BaseLayoutStyles.alignItemsStart
                            +BaseLayoutStyles.alignContentAtStart
                            +BaseLayoutStyles.alignSelfStart
                            +BaseLayoutStyles.top0px

                        }
                        styledArticle {
                            attrs.id = "article"
//                        val tableHeight = document.getElementById("outerTable")?.clientHeight
//                        val headerHeight = document.getElementById("headerRow")?.clientHeight
//                        val navHeight = document.getElementById("navRow")?.clientHeight
//                        var articleHeight: Int = 0
//                        if (tableHeight != null && headerHeight != null && navHeight != null) {
//                            articleHeight = tableHeight!! - headerHeight!! - navHeight!!
//                            document.getElementById("article")?.setAttribute("clientHeight", articleHeight.toString())
//                        }
//                        +"$tableHeight"
//                        br{}
//                        +"$headerHeight"
//                        br{}
//                        +"$navHeight"
                            css {

                                +BaseLayoutStyles.articleHeight
//                            +BaseLayoutStyles.fullWidth
//                            +BaseLayoutStyles.paddingMarginZero
                                +BaseLayoutStyles.marginHorizontal2em
                                +BaseLayoutStyles.paddingHorizontal1em
                                +BaseLayoutStyles.alignContentAtStart
                                +BaseLayoutStyles.alignSelfStretch
                                +BaseLayoutStyles.alignTextCenter

                                +BaseLayoutStyles.article

                                +BaseLayoutStyles.overflowAuto

//                            +BaseLayoutStyles.solidBorder1px
                            }

//                        document.getElementById("article").style.style.height = articleHeight

                            styledDiv {
                                css {
                                    +BaseLayoutStyles.alignSelfStretch
//                                +BaseLayoutStyles.dottedBorder1px
                                }
                                setArticleContent()
                            }
                            //Element to fill rest of TableRow
//                        styledDiv {
//                            css {
//                                +BaseLayoutStyles.alignSelfStretch
//                                +BaseLayoutStyles.dottedBorder1px
//                                +BaseLayoutStyles.alignItemsStretch
//
//
//                            }
//                        }
                        }
                    }
                }
            }
        }
        styledTable {
            attrs.id = "footerTable"
            css {
                +BaseLayoutStyles.noMarginNoPaddingNoBorders
                +BaseLayoutStyles.baseBackgroundColor
                +BaseLayoutStyles.fullWidth
                +BaseLayoutStyles.stdtTableLayout
                +BaseLayoutStyles.alignContentAtEnd
                +BaseLayoutStyles.overflowHidden
                +BaseLayoutStyles.standardFont
//                +BaseLayoutStyles.dottedBorder1px
            }

            styledTbody {
                attrs.id = "footerTableBody"
                css {
                    +BaseLayoutStyles.noMarginNoPaddingNoBorders
//                    +BaseLayoutStyles.solidBorder1px
                    +BaseLayoutStyles.bottom0px
                }

                styledTr {
                    attrs.id = "footerRow"
                    css {
                        +BaseLayoutStyles.paddingMarginZero
                        +BaseLayoutStyles.bottom0px
                        +BaseLayoutStyles.noMarginNoPaddingNoBorders
                        +BaseLayoutStyles.alignContentAtCenter
                    }
                    styledTd {
                        css {
                            +BaseLayoutStyles.paddingMarginZero
                            +BaseLayoutStyles.noMarginNoPaddingNoBorders
                            +BaseLayoutStyles.footerHeight
                            +BaseLayoutStyles.footer
                        }
                        styledFooter {
                            css {
                                +BaseLayoutStyles.fullWidth
                                +BaseLayoutStyles.alignTextCenter
                                +BaseLayoutStyles.noMarginNoPaddingNoBorders
                            }
                            +"Copyright \u00A9 $fullCompanyName 2021"
                        }
                    }
                }
            }
        }
    }


    private fun RBuilder.setArticleContent () : ReactElement {
        when(state.articleToShow) {
            "" -> {
                return welcomeContent {
                }
            }
            "Software Entwicklung" -> {
                return softwareDevelopmentContent {
                }
            }
            "Optimierung des Entwicklungsprozesses" -> {
                return optimizingOfDevelopmentProcessContent {
                    optimizingText = ""
                    numberOfDataPoints = 0
                    timeLastStateChange = 1.0
                    updateGraphLoopJob = null
                }
            }
            "Kontakt" -> {
                return contactContent {

                }
            }
            "Impressum" -> {
                return imprintContent {

                }
            }
            "über diese Seite" -> {
                return aboutThisPageContent {  }
            }
            else -> {
                return welcomeContent {
                    welcomeText = "Else Case"
                }
            }
        }
    }

//    override fun componentWillUnmount() {
//        super.componentWillUnmount()
//    }
}
