package content

import react.*
import react.dom.li
import styled.*


@ExperimentalJsExport
@JsExport external interface SoftwareDevelopmentContentProps : RProps {
    var softwareDevelopmentText: String
}

@ExperimentalJsExport
@JsExport data class SoftwareDevelopmentContentState(
    val softwareDevelopmentText: String = "Software Development"
) : RState

@ExperimentalJsExport
class SoftwareDevelopmentContent @ExperimentalJsExport constructor(props: SoftwareDevelopmentContentProps) : RComponent<SoftwareDevelopmentContentProps, SoftwareDevelopmentContentState>(props){
    init {
        state = SoftwareDevelopmentContentState(
        )
    }

    override fun RBuilder.render() {
        styledH1 {
            css {
            }
            attrs.text("Softwareentwicklung")
        }
        styledDiv {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            styledP {

                +"Wir entwickeln Software bevorzugt in Java, Kotlin und C/C++."
            }
            styledP {
                +"Dabei sind wir uns bewusst darüber, dass jede Programmiersprache ihre Stärken und Schwächen hat, "
                +"die auch Einfluss auf den Entwicklungsprozess und den Aufbau der CI/CD Pipeline hat."
            }
            styledP {
                +"In der Entwicklung bevorzugen wir die Testgetriebene Arbeit nach Scrum. "
            }
            styledP {
                +"Unsere Mitarbeiter haben schon an einer Vielzahl unterschiedlichster Projekte mitgewirkt, wie der: "
                styledUl {
                    li {
                        +"Analyse des Schwingungsverhaltens von Ventilatoren"
                    }
                    li {
                        +"Auslegung einer Foliendruckpresse"
                    }
                    li {
                        +"Entwicklung einer Echtzeitsteuerung für eine Foliendruckpresse"
                    }
                    li {
                        +"Entwicklung einer Echtzeitsteuerung für eine Linienschneidemaschine"
                    }
                    li {
                        +"Entwicklung einer automatischen Parameterbestimmung für PID-Regler"
                    }
                    li {
                        +"Entwicklung einer nahezu Bewegungslosen Kommutierung"
                    }
                    li {
                        +"Weiterentwicklung eines Linux-basierten Echtzeitsystems"
                    }
                    li {
                        +"Weiterentwicklung eines Konfigurations und Überwachungsprogramms für Umrichter"
                    }
                    li {
                        +"Weiterentwicklung für eine Beratersoftware für Versicherungsmakler (Online und Offline)"
                    }
                    li {
                        +"Entwicklung einer Android app zur Zeiterfassung"
                    }
                    li {
                        +"und andere"
                    }
                }
            }
        }
    }
}

@ExperimentalJsExport
fun RBuilder.softwareDevelopmentContent (handler: SoftwareDevelopmentContentProps.() -> Unit): ReactElement {
    return child(SoftwareDevelopmentContent::class) {
        this.attrs(handler)
    }
}