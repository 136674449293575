package kotlinReactComponents

import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css
import styled.styledDiv


@ExperimentalJsExport
@JsExport external interface CompanySloganProps : RProps {
    var topLineText: String
    var bottomLineText: String
    var sloganStyleRuleSet: RuleSetClass
    var topLineStyleRuleSets: RuleSetClass
    var bottomLineStyleRuleSet: RuleSetClass
}

@ExperimentalJsExport
@JsExport data class CompanySloganState(
    var topLineText: String = "",
    var bottomLineText: String = "",
    var sloganStyleRuleSet: RuleSetClass = RuleSetClass(),
    var topLineStyleRuleSets: RuleSetClass = RuleSetClass(),
    var bottomLineStyleRuleSet: RuleSetClass = RuleSetClass(),
) : RState

@ExperimentalJsExport
class CompanySlogan @OptIn(ExperimentalJsExport::class) constructor(props: CompanySloganProps) : RComponent<CompanySloganProps, CompanySloganState>(props){
    init {
        state = CompanySloganState(
            topLineText = props.topLineText,
            bottomLineText = props.bottomLineText,
            sloganStyleRuleSet = props.sloganStyleRuleSet,
            topLineStyleRuleSets = props.topLineStyleRuleSets,
            bottomLineStyleRuleSet = props.bottomLineStyleRuleSet
        )
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                for (i in 0..state.sloganStyleRuleSet.ruleSets.lastIndex) {
                    +state.sloganStyleRuleSet.ruleSets[i]
                }
            }
            styledDiv {
                css {
                    for (i in 0..state.topLineStyleRuleSets.ruleSets.lastIndex) {
                        +state.topLineStyleRuleSets.ruleSets[i]
                    }
                }
                attrs.text(state.topLineText)
            }
            styledDiv {
                css {
                    for (i in 0..state.bottomLineStyleRuleSet.ruleSets.lastIndex) {
                        +state.bottomLineStyleRuleSet.ruleSets[i]
                    }
                }
                attrs.text(state.bottomLineText)
            }
        }
    }
}