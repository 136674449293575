package kotlinReactComponents

import DropdownMenuStyles
import kotlinx.html.js.onMouseDownFunction
import kotlinx.html.js.onMouseOutFunction
import kotlinx.html.js.onMouseOverFunction
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import styled.css
import styled.styledDiv
import styled.styledSpan


@ExperimentalJsExport
@JsExport external interface NavigationElementProps : RProps {
    var text: String
    var onMouseDownDropdownMenu: (Event) -> Unit
    var textStyleRuleSet: RuleSetClass
}

@ExperimentalJsExport
@JsExport data class NavigationElementState(
    val text: String = "",
    val mouseIsOver: String = "",
    val textStyleRuleSet: RuleSetClass = RuleSetClass()
): RState

@ExperimentalJsExport
class NavigationElement @OptIn(ExperimentalJsExport::class) constructor(props: NavigationElementProps) : RComponent<NavigationElementProps, NavigationElementState>(props){
    init {
        state = NavigationElementState(
            text = props.text,
            mouseIsOver = "",
            textStyleRuleSet = props.textStyleRuleSet,
            )
    }

    override fun RBuilder.render() {
        styledDiv {
            css {
                +DropdownMenuStyles.dropdown
                for ( rules in state.textStyleRuleSet.ruleSets) {
                    +rules
                }
            }
            attrs {
                onMouseOverFunction = { event ->
                    setState(
                        NavigationElementState(
                            text = state.text,
                            mouseIsOver = (event.target as HTMLElement).innerText,
                            textStyleRuleSet = state.textStyleRuleSet
                        )
                    )
                }
                onMouseOutFunction = {
                    setState(
                        NavigationElementState(
                            text = state.text,
                            mouseIsOver = "",
                            textStyleRuleSet = state.textStyleRuleSet
                        )
                    )
                }
            }
            styledSpan {
                styledDiv {
                    css {
                        if (state.mouseIsOver == state.text) {
                            +DropdownMenuStyles.boldText
                        } else {
                            +DropdownMenuStyles.normalText
                        }
                    }
                    attrs.text(state.text)
                    attrs {
                        onMouseDownFunction = { event ->
                            props.onMouseDownDropdownMenu (event)
                        }
                    }
                }
            }
        }
    }
}


