package content

import BaseLayoutStyles
import kotlinx.html.*
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onMouseDownFunction
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.option
import styled.*


@ExperimentalJsExport
@JsExport external interface ContactContentProps : RProps {
    var contactText: String
}

@ExperimentalJsExport
@JsExport data class ContactContentState(
    val salutation: String = "",
    val firstName: String = "",
    val lastName: String = "",
    val emailAddress: String = "",
    val telephoneNumber: String = "",
    val topic: String = "",
    val message: String = ""

) : RState

@ExperimentalJsExport
class ContactContent @ExperimentalJsExport constructor(props: ContactContentProps) : RComponent<ContactContentProps, ContactContentState>(props) {
    init {
        state = ContactContentState(
        )
    }

    override fun RBuilder.render() {
        val inputFieldSize = "50"
        styledH1 {
            css {

            }
            +"Kontaktformular"
        }
        styledP {
            css {
            }
            +"Die mit \"*\" markierten Felder sind Pflichtfelder."
        }


        styledTable {
            css {
                +BaseLayoutStyles.alignTextLeft
                +BaseLayoutStyles.marginHorizontalAuto
            }
            styledTr {
                css {
                }
                styledTd {
                    +"Anrede*: "
                }
                styledTd {
                    styledSelect {
                        val optionText = "Bitte wählen"
                        attrs {
                            onChangeFunction = { event ->
                                val eventValue = (event.target as HTMLInputElement).value
                                if (eventValue != optionText) {
                                    setState(
                                        ContactContentState(
                                            salutation = eventValue,
                                            firstName = state.firstName,
                                            lastName = state.lastName,
                                            emailAddress = state.emailAddress,
                                            telephoneNumber = state.telephoneNumber,
                                            topic = state.topic,
                                            message = state.message
                                        )
                                    )
                                }
                            }
                        }
                        styledOption {
                            attrs.text(optionText)
                        }
                        option {
                            +"Frau"
                        }
                        option {
                            +"Herr"
                        }

                    }
                }
            }
            styledTr {
                css {
                }
                styledTd {
                    +"Vorname:*      "
                }
                styledTd {
                    styledInput {
                        css {

                        }
                        attrs {
                            size = inputFieldSize
                            title = "Bitte geben Sie Ihren Vornamen ein"
                            type = InputType.text
                            value = state.firstName
                            onChangeFunction = { event ->
                                setState(
                                    ContactContentState(
                                        salutation = state.salutation,
                                        firstName = (event.target as HTMLInputElement).value,
                                        lastName = state.lastName,
                                        emailAddress = state.emailAddress,
                                        telephoneNumber = state.telephoneNumber,
                                        topic = state.topic,
                                        message = state.message
                                    )
                                )
                            }
                        }
                    }
                }
            }
            styledTr {
                css {
                }
                styledTd {
                    +"Nachname:*     "
                }
                styledTd {
                    styledInput {
                        css {

                        }
                        attrs {
                            size = inputFieldSize
                            title = "Bitte geben Sie Ihren Nachnamen ein"
                            type = InputType.text
                            value = state.lastName
                            onChangeFunction = { event ->
                                setState(
                                    ContactContentState(
                                        salutation = state.salutation,
                                        firstName = state.firstName,
                                        lastName = (event.target as HTMLInputElement).value,
                                        emailAddress = state.emailAddress,
                                        telephoneNumber = state.telephoneNumber,
                                        topic = state.topic,
                                        message = state.message
                                    )
                                )
                            }
                        }
                    }
                }
            }
            styledTr {
                css {
                }

                styledTd {
                    +"Telefonnummer:*     "
                }
                styledTd {
                    styledInput {
                        css {

                        }
                        attrs {
                            size = inputFieldSize
                            title = "Bitte geben Sie Ihre Telefonnummer ein"
                            type = InputType.text
                            value = state.telephoneNumber
                            onChangeFunction = { event ->
                                setState(
                                    ContactContentState(
                                        salutation = state.salutation,
                                        firstName = state.firstName,
                                        lastName = state.lastName,
                                        emailAddress = state.emailAddress,
                                        telephoneNumber = (event.target as HTMLInputElement).value,
                                        topic = state.topic,
                                        message = state.message
                                    )
                                )
                            }
                        }
                    }
                }
            }
            styledTr {
                css {
                }
                styledTd {
                    +"E-Mail-Adresse:*     "
                }
                styledTd {
                    styledInput {
                        css {
                        }
                        attrs {
                            size = inputFieldSize
                            title = "Bitte geben Sie Ihre E-Mail-Adresse ein"
                            type = InputType.text
                            value = state.emailAddress
                            onChangeFunction = { event ->
                                setState(
                                    ContactContentState(
                                        salutation = state.salutation,
                                        firstName = state.firstName,
                                        lastName = state.lastName,
                                        emailAddress = (event.target as HTMLInputElement).value,
                                        telephoneNumber = state.telephoneNumber,
                                        topic = state.topic,
                                        message = state.message
                                    )
                                )
                            }
                        }
                    }
                }
            }
            styledTr {
                css {
                }
                styledTd {
                    +"Betreff:* "
                }
                styledTd {
                    styledInput {
                        css {
                        }
                        attrs {
                            size = inputFieldSize
                            title = "Bitte geben Sie den Betreff Ihrer Nachricht"
                            type = InputType.text
                            value = state.topic
                            onChangeFunction = { event ->
                                setState(
                                    ContactContentState(
                                        salutation = state.salutation,
                                        firstName = state.firstName,
                                        lastName = state.lastName,
                                        emailAddress = state.emailAddress,
                                        telephoneNumber = state.telephoneNumber,
                                        topic = (event.target as HTMLInputElement).value,
                                        message = state.message
                                    )
                                )
                            }
                        }
                    }
                }
            }
            styledTr {
                css {
                }
                styledTd {
                    +"Ihre Nachricht:* "
                }
                styledTd {
                    styledTextArea(rows = "20", cols = "80", wrap = TextAreaWrap.soft) {
                        css {
                        }

                        attrs {
                            title = "Bitte geben Sie Ihre Nachricht ein"
                            contentEditable = true
                            onChangeFunction = { event ->
                                setState(
                                    ContactContentState(
                                        salutation = state.salutation,
                                        firstName = state.firstName,
                                        lastName = state.lastName,
                                        emailAddress = state.emailAddress,
                                        telephoneNumber = state.telephoneNumber,
                                        message = (event.target as HTMLInputElement).value,
                                    )
                                )
                            }
                        }
                    }
                }
            }
        }
        styledDiv {
            css {
                +BaseLayoutStyles.alignContentAtCenter



            }
            styledButton {
                css {
                    +BaseLayoutStyles.stdButton
                }
                attrs.type = ButtonType.button
                attrs.text("Abschicken")

                attrs {
                    onMouseDownFunction = { event -> println("Pressed")
                        if (mandatoryFieldsAreSet(state)) {
                            sendData(state)
                        }
                    }
                }
            }
        }
    }

    private fun sendData(state: ContactContentState) {

    }

    private fun mandatoryFieldsAreSet(state: ContactContentState) : Boolean {
        return when {
            state.firstName == "" -> false
            state.lastName == "" -> false
            state.emailAddress == "" -> false
            state.salutation == "" -> false
            else -> true
        }

    }
}

@ExperimentalJsExport
fun RBuilder.contactContent (handler: ContactContentProps.() -> Unit): ReactElement {
    return child(ContactContent::class) {
        this.attrs(handler)
    }
}