package content

import BaseLayoutStyles
import io.kvision.html.Ul
import io.kvision.types.LocalDateTime
import kotlinReactComponents.kVision.sprintChart
import kotlinx.coroutines.*
import react.*
import react.dom.br
import react.dom.li
import react.dom.p
import react.dom.ul
import styled.*


@ExperimentalJsExport
@JsExport external interface OptimizingOfDevelopmentProcessContentProps : RProps {
    var optimizingText: String
    var numberOfDataPoints: Int
    var timeLastStateChange: Double
    var updateGraphLoopJob: Any?
}

@ExperimentalJsExport
@JsExport data class OptimizingOfDevelopmentProcessContentState(
    val optimizingText: String = "Optimierung des Software-Entwicklungsprozesses",
    val numberOfDataPoints: Int = 0,
    val timeLastStateChange: Double = 0.0,
    val updateGraphLoopJob: Any?
) : RState

@ExperimentalJsExport
class OptimizingOfDevelopmentProcessContent @ExperimentalJsExport constructor(props: OptimizingOfDevelopmentProcessContentProps) : RComponent<OptimizingOfDevelopmentProcessContentProps, OptimizingOfDevelopmentProcessContentState>(props){
    init {
        state = OptimizingOfDevelopmentProcessContentState(
            numberOfDataPoints = 14,
            timeLastStateChange = LocalDateTime.now(),
            updateGraphLoopJob = null
        )
    }

    private var updateState : Deferred<Boolean>? = null
    private var launchedJob : Job? = null

    override fun RBuilder.render() {
        styledH1 {
            css {

            }
            +"Optimierung der Softwareentwicklung"
        }
        styledH3 {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            +"Sehen die Burn-Down-Charts ihrer Sprints manchmal so aus?"
            styledDiv {
                css {
                    +BaseLayoutStyles.alignContentAtCenter
                    +BaseLayoutStyles.alignItemsCenter
                    +BaseLayoutStyles.alignSelfCenter
                    +BaseLayoutStyles.alignTextCenter
                    +BaseLayoutStyles.marginHorizontalAuto
                }
//            val timeNow = LocalDateTime.now()
//            br{}
//            +"TimeNow = $timeNow"
//            br{}
//            +"numberOfDatapoints = ${state.numberOfDataPoints}"
//            br{}
//            +"lastStateChange = ${state.timeLastStateChange}"
//            br{}
//            +"job = ${props.updateGraphLoopJob}"


//            if (state.updateGraphLoopJob == null) {
//                launchedJob = GlobalScope.launch {
//                    delay(0)
//                    updateGraphLoop(launchedJob)
//                }
//                setState (
//                    OptimizingOfDevelopmentProcessContentState (
//                        optimizingText = state.optimizingText,
//                        numberOfDataPoints = state.numberOfDataPoints,
//                        timeLastStateChange = LocalDateTime.now(),
//                        updateGraphLoopJob =  GlobalScope.launch {
//                            delay(0)
//                            updateGraphLoop()
//                        }
//                   )
//                )
//            }


                val fullDataRealSprint = arrayOf(63, 61, 54, 48, 40, 40, 40, 50, 42, 38, 33, 30, 25, 20)

                sprintChart {
                    currentDataset = fullDataRealSprint
                }
            }

        }
        styledH3 {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            br{}
            p {
                +"Treten bei Ihnen immer wieder Bugs in Funktionen auf, die schon einmal Funktioniert haben?"
            }
            p {
                +"Gibt es instabile Zustände ihrer Programme?"
            }
            p{
            +"Wird die Implementierung neuer Features immer langsamer?"
            }
            p{
            +"Oder haben Ihre Programme Performanceprobleme?"
            }
            br{}
            p {
                +"Dann sollten Sie Ihren Entwicklungsprozess überprüfen!"
            }

        }

        styledH3 {
            css {
                +BaseLayoutStyles.alignTextCenter
            }
            br{}
            p {
                +"Wir helfen Ihnen gerne."
            }
            br{}
        }
        styledH3 {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            +"Bei der Optimierung der Softwareentwicklung gehen wir wie folgt vor:"
        }
        styledP {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            +"Zunächst machen wir eine Bestandsaufnahme und untersuchen wie die Entwicklung durchgeführt wird. "
            p {
                +"Dabei betrachten wir unter anderem folgende Fragestellungen: "

                ul {
                    li {
                        +"Wie wird entschieden was entwickelt wird?"
                    }
                    li {
                        +"Wie genau/kleinteilig ist die Planung?"
                    }
                    li {
                        +"Wie wird entschieden, wie genau/kleinteilig die Planung sein sollte?"
                    }
                    li {
                        +"Wie wird überprüft, wie ob ein Entwicklungsschritt das erreicht, was er erreichen sollte?"
                    }

                    li {
                        +"Wie sind die Verantwortlichkeiten verteilt?"
                    }
                    li {
                        +"Gibt es Kompetenzüberschneidungen? "
                        br {}
                        +"- Wenn ja, wie sind sie entstanden und worauf beruhen sie?"
                    }
                    li {
                        +"Wann und wie werden die Wünsche der Kunden ermittelt?"
                    }
                    li {
                        +"Wie geht die Zufriedenheit der Kunden/Nutzer in den Entwicklungsprozess mit ein?"
                    }
                    li {
                        +"Wie werden die einzelnen Entwicklungsschritte bestimmt?"
                    }
                    li {
                        +"Wie werden die einzelnen Aufgaben verteilt?"
                    }
                    li {
                        +"Wie wird entschieden, ob eine Teilentwicklung den Anforderungen entspricht?"
                    }
                    li {
                        +"Wie sieht der Prozess vom Schreiben einer Zeile Code bis zum Release aus?"
                    }
                    li {
                        +"Wie wird die Qualitätssicherung durchgeführt?"
                    }
                    li {
                        +"Wie ist der Code strukturiert?"
                    }
                    li {
                        +"Gibt es Spaghetti-, Ravioli- oder Lasagne - Code?"
                    }
                    li {
                        +"Werden etablierte Architekturmuster verwendet?"
                    }
                    li {
                        +"Werden Dinge mehrfach entwickelt?"
                    }
                    li {
                        +"Gibt es Doppelungen im Code?"
                    }
                }
            }
        }
        styledP {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            +"Abhängig von den Ergebnissen werden Problemfelder identifiziert und beschrieben. "
            br{}
            +"Anschließend werden mögliche Maßnahmen zur Beseitigung der Probleme entwickelt und vorgestellt."
        }
        styledP {
            css {
                +BaseLayoutStyles.alignTextLeft
            }
            +"Bei der Umsetzung der Maßnahmen unterstützen und begleiten wir sie gerne. "
            br{}
            +"Bei Bedarf auch mit Schulungen und Workshops."
        }
    }

//    private suspend fun updateGraphLoop () {
//        while (true)
//        {
//          if (LocalDateTime.now() - 2000.0 > state.timeLastStateChange)
//                setState {
//                    OptimizingOfDevelopmentProcessContentState(
//                        optimizingText = state.optimizingText,
//                        numberOfDataPoints = if (state.numberOfDataPoints < 14) {
//                            (state.numberOfDataPoints + 1)
//                        } else {
//                            0
//                        },
//                        numberOfDataPoints = 3,
//                        timeLastStateChange = LocalDateTime.now(),
//                        updateGraphLoopJob = state.updateGraphLoopJob,
//                    )
//                }
//            }
//            delay(500)
//        }
//    }

    suspend fun delay500 ():Boolean {
        delay (500)
        return true
    }

//    override fun componentWillUnmount() {
//        super.componentWillUnmount()
//        if (state.updateGraphLoopJob is Job) {
//            (state.updateGraphLoopJob as Job).cancel()
//        }
//    }
}



@ExperimentalJsExport
fun RBuilder.optimizingOfDevelopmentProcessContent (handler: OptimizingOfDevelopmentProcessContentProps.() -> Unit): ReactElement {
    return child(OptimizingOfDevelopmentProcessContent::class) {
        this.attrs(handler)
    }
}